<!--
 * @Description:停车场运营管理 运营管理 缴费记录 详情 parkPayRecordInfo
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-07-02 15:20:43
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <div>
      <span @click="handleClickBack">返回</span>
    </div>
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="订单号">
              <el-input v-model="searchForm.orderSequence"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input v-model="searchForm.plateNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="缴费方式">
              <el-select v-model="searchForm.paymentMethodCode"
                         placeholder="全部">
                <el-option v-for="item in payMethodList"
                           :key="item.value"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="缴费编号">
              <el-input v-model="searchForm.paymentSequence"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场名称">
              <el-select v-model="parkId"
                         placeholder="全部"
                         clearable>
                <el-option v-for="item in parkNameList"
                           :key="item.value"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="缴费时间">
              <el-date-picker v-model="lastpaymentTime"
                              type="datetimerange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="topButton">
        <el-button type="primary"
                   @click="queryButton">查 询</el-button>
        <el-button type="info"
                   @click="resetForm"
                   style="margin-left:17px;">重 置</el-button>
      </el-row>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>缴费记录</span>
        <el-button type="info"
                   class="tableTitleButton"
                   @click="exportFile">导出</el-button>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="isFirst">
          </template>
        </dt-table>
      </el-row>

    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import { timeDuration } from '@/utils/parkStopTime.js'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: 'orderSequence',
          label: '订单号',
        },
        {
          prop: 'plateNumber',
          label: '车牌号',
        },
        {
          prop: 'paymentSequence',
          label: '缴费编号',
        },
        {
          prop: 'paymentMoneyAmount',
          label: '缴费金额（元）',
        },
        {
          prop: 'payMethod',
          label: '缴费方式',
        },
        {
          prop: 'paymentTime',
          label: '缴费时间',
        },
        {
          prop: 'paymentTypeDesc',
          label: '缴费类型',
        },
      ],
      tableList: {
        list: [
        ],
      },
      searchForm: {}, // 查询条件
      pageNum: 1,
      pageSize: 15,
      parkId: "",
      parkNameList: [],// 停车场信息
      payMethodList: [], //缴费方式
      lastpaymentTime: [],// 缴费时间
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      value1: '',
      value2: '',
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.lastpaymentTime = this.$route.query.queryTime
    this.parkId = this.$route.query.parkId
    this.queryTableList()
    this.getPaidType()
    this.getparkNameList()
  },
  //方法集合
  methods: {
    // 导出
    exportFile () {
      let timeMilli = timeDuration(this.lastpaymentTime[1], this.lastpaymentTime[0])
      // 三天内
      if (timeMilli <= 259200) {
        this.exportFileFunction()
      }
      else {
        this.$message.warning('请导出三天内的数据！')
      }
    },
    exportFileFunction () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$axios.post(this.$downloadBaseUrl + "payment/downloadPayRecordOut", this.searchForm, {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
      }).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "缴费记录.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
        loading.close()
      }).catch(() => {
        loading.close()
        this.$message.error("导出失败")
      })
    },
    //初始化表格数据
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.searchForm['paymentStartTime'] = this.lastpaymentTime[0]
      this.searchForm['paymentEndTime'] = this.lastpaymentTime[1]
      this.searchForm['parkId'] = this.parkId
      this.$parkPayRecord.queryOrderPayRecordOut(this.searchForm).then(res => {
        if (res.resultEntity) {
          this.tableList.list = res.resultEntity.content
          this.paginationConfig.total = res.resultEntity.totalElements
          this.tableList.list.forEach(item => {
            item.paymentMoneyAmount = Number(Number(item.paymentMoneyAmount) / 100).toFixed(2)
            item.plateNumber = item.order[0].plateNumber
          });
        }
        else {
          this.tableList.list = []
        }
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    //顶部查询
    queryButton () {
      this.listMap.pageNum = 1
      this.pageNum = 1;
      if (this.lastpaymentTime) {
        this.searchForm.paymentStartTime = this.lastpaymentTime[0]
        this.searchForm.paymentEndTime = this.lastpaymentTime[1]
      } else {
        this.searchForm.paymentStartTime = this.$route.query.queryTime[0]
        this.searchForm.paymentEndTime = this.$route.query.queryTime[1]
      }
      this.queryTableList();
    },
    //重置
    resetForm () {
      this.searchForm = {}
      this.searchForm.paymentStartTime = this.$route.query.queryTime[0]
      this.searchForm.paymentEndTime = this.$route.query.queryTime[1]
      this.searchForm.parkId = this.$route.query.parkId
      this.lastpaymentTime = this.$route.query.queryTime
      this.parkId = this.$route.query.parkId
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList();
    },
    // 获取停车场
    getparkNameList () {
      let info = {
        "columnName": ["park_id", "park_name"],
        "tableName": "tb_park",
        "whereStr": [
          {
            "colName": "park_type_code",
            "value": 2
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        this.parkNameList = res.resultEntity
      })
    },
    //获取缴费方式
    getPaidType () {
      let info = {
        columnName: ["value", "name"],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '58184EF53AA9411AB1A2F49E6DC3D223',
          },
        ]
      }
      this.$queryDict.queryDict(info).then((response) => {
        let data = response.resultEntity
        this.payMethodList = data
      })
    },
    // 返回
    handleClickBack () {
      this.$router.go(-1)
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 185px;
//@import url(); 引入公共css类
// 最外层div
.mainbody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
</style>

